<template lang="pug">
    div.flex.horizontal-expand.horizontal-centralize.vertical-centralize()
        div.flex.horizontal-expand.expand-vh-vertical(style="min-height: 100vh; background: linear-gradient(to right, #f2f2f2, #e5e5e5);; display: flex; width: 100%; justify-content: center;")
          v-flex.pa-3(xs12 md3 v-if="passo===1")
              v-form(ref="form")
                  div.flex.horizontal-expand.horizontal-centralize.vertical-centralize.column
                      div.flex.expande-horizontal
                        v-btn(icon @click="$router.go(-1)" class="elevation-1" color="grey darken-3")
                          v-icon mdi-chevron-left
                      div.flex.expande-horizontal.centraliza
                        img(height="80" src="/img/logo.png" style="background: #333; padding: 12px; border-radius: 6px;")
                      div.expande-horizontal.centraliza
                        span.margin-vertical.mb-6.fonte.text-center.mt-6.pr-6.pl-6.font-weight-thin.black--text Faça seu cadastro abaixo.

                      div.flex.horizontal-expand.column
                          v-flex(xs12)
                              span.fonte.black--text Nome Completo
                          v-flex(xs12)
                              v-text-field(
                                  dense
                                  
                                  v-model="payload.nome"
                                  placeholder="Digite o seu nome completo"
                                  :rules="[v => !!v || 'Preencha aqui']"
                                  :color="$theme.primary"
                                  solo-inverted
                                  flat
                              )
                      
                      div.flex.horizontal-expand.column
                          v-flex(xs12)
                              span.fonte.black--text E-mail
                          v-flex(xs12)
                              v-text-field(
                                  dense
                                  
                                  v-model="payload.email"
                                  placeholder="Informe o seu melhor e-mail"
                                  :rules="[v => !!v || 'Preencha aqui']"
                                  :color="$theme.primary"
                                  solo-inverted
                                  flat
                              )
                      
                      div.flex.horizontal-expand.column
                          v-flex(xs12)
                              span.fonte.black--text CPF
                          v-flex(xs12)
                              v-text-field(
                                  dense
                                  
                                  v-model="payload.cpf"
                                  placeholder="000.000.000-00"
                                  :rules="[v => !!v || 'Preencha aqui']"
                                  v-mask="['###.###.###-##']"
                                  :color="$theme.primary"
                                  solo-inverted
                                  flat
                              )
                      
                      div.flex.horizontal-expand.column
                          v-flex(xs12)
                              span.fonte.black--text Nome da Sua Empresa
                          v-flex(xs12)
                              v-text-field(
                                  dense
                                  
                                  v-model="payload.nome_empresa"
                                  placeholder="SGVPro"
                                  :rules="[v => !!v || 'Preencha aqui']"
                                  :color="$theme.primary"
                                  solo-inverted        
                                  flat
                              )
                          //- v-flex(xs12)
                          //-     v-select(
                          //-         dense
                          //-         v-model="payload.segmento"
                          //-         placeholder="ex: Bares"
                          //-         :items="['Restaurantes', 'Hamburguerias', 'Pizzarias', 'Lanchonetes', 'Bares']"
                          //-         :rules="[v => !!v || 'Preencha aqui']"
                          //-         :color="$theme.primary"
                          //-         solo-inverted        
                          //-         flat
                          //-     )
                      //- div.flex.horizontal-expand.column
                      //-     v-flex(xs12)
                      //-         span.fonte.black--text Cargo na Empresa
                      //-     v-flex(xs12)
                      //-         v-text-field(
                      //-             dense
                      //-             
                      //-             v-model="payload.nome_empresa"
                      //-             placeholder="SGVPro"
                      //-             :rules="[v => !!v || 'Preencha aqui']"
                      //-             :color="$theme.primary"
                      //-             solo-inverted        
                      //-             flat
                      //-         )
                      
                      div.flex.horizontal-expand.column
                          v-flex(xs12)
                              span.fonte.black--text CNPJ da Sua Empresa
                          v-flex(xs12)
                              v-text-field(
                                  dense
                                  :rules="[v => !!v || 'Preencha aqui']"
                                  v-model="payload.cnpj"
                                  placeholder="00.000.000/0000-00"
                                  v-mask="['##.###.###/####-##']"
                                  :color="$theme.primary"
                                  solo-inverted        
                                  flat
                              )
                      //- div.flex.horizontal-expand.column
                      //-     v-flex(xs12)
                      //-         span.fonte.black--text Seguimento do negócio
                      //-     v-flex(xs12)
                      //-         v-text-field(
                      //-             dense
                      //-             
                      //-             v-model="payload.cnpj"
                      //-             placeholder="00.000.000/0000-00"
                      //-             v-mask="['##.###.###/####-##']"
                      //-             :rules="[v => !!v || 'Preencha aqui']"
                      //-             :color="$theme.primary"
                      //-             solo-inverted        
                      //-             flat
                      //-         )
                      

                      div.flex.horizontal-expand.column
                          v-flex(xs12)
                              span.fonte.black--text Senha para login
                          v-flex(xs12)
                              v-text-field(
                                  dense
                                  
                                  v-model="payload.senha"
                                  :rules="[v => !!v || 'Preencha este campo']"
                                  placeholder="******"
                                  type="password"
                                  :color="$theme.primary"
                                  solo-inverted
                                  flat
                              )

                      div.flex.horizontal-expand.column
                          v-flex(xs12)
                              span.fonte.black--text Confirme a Senha para login
                          v-flex(xs12)
                              v-text-field(
                                  dense
                                  
                                  v-model="confirm_senha"
                                  :rules="[v => !!v || 'Preencha aqui']"
                                  placeholder="******"
                                  type="password"
                                  :color="$theme.primary"
                                  solo-inverted
                                  flat
                              )
                      
                      div.flex.horizontal-expand.column
                          v-flex(xs12)
                              span.fonte.red--text {{ erro }}
                          

                      //- div.mb-6.expande-horizontal.centraliza
                      //-   v-switch(class="pa-2"  color="light-green" outlined v-model="termos" label="Ao criar a conta você concorda com os nossos termos de uso.")
                      div.mb-6.expande-horizontal.centraliza
                          v-btn.fonte(
                            :disabled="senhaOk && termos ? false : true"
                            rounded
                            dark
                            class="fonte"
                            @click="valida_form"
                            :color="$theme.primary"
                          ) 
                            span.fonte Finalizar e Cadastrar
                      
                      div.mb-6.flex.horizontal-expand.column
                          v-btn.fonte(
                              @click="$router.go(-1)"
                              text
                              block
                              
                              :color="$theme.primary"
                          )
                            span.black--text Tem um cadastro? Faça Login

                      //- h3.margin-vertical.mb-12.fonte.text-center Ao criar uma conta, você concorda com a nossa Política de Privacidade.

                
               

</template>

<script>
import { mapActions, mapGetters } from "vuex";
import validarCPF from "validar-cpf";

export default {
  props: ["sponsor_id"],
  data() {
    return {
      passo: 1,
      modal: false,
      termos: true,
      erro: "",
      confirm_senha: "",
      payload: {
        transaction_senha: "",
        senha: "",
        sponsor: {
          _id: "nosponsor"
        }
      },
      senhaOk: false
    };
  },
  watch: {
    confirm_senha(val) {
      if (val === this.payload.senha) {
        this.senhaOk = true;
        this.erro = "";
      } else {
        this.senhaOk = false;
        this.erro = "Senhas não correspondem";
      }
    }
  },
  methods: {
    ...mapActions(["createUser", "createGlobalMessage"]),
    valida_form() {
      if (this.$refs.form.validate()) {
        if (validarCPF(this.payload.cpf)) {
          this.createUser(this.payload);
        } else {
          this.createGlobalMessage({
            timeout: 6000,
            type: "error",
            message: "CPF inválido"
          });
        }
      }
    }
  }
};
</script>
